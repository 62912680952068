export const apiDomain = 'api.quant-autoplace.ru';
export const timezoneOffsetCurrent = 4;
export const domains = [
  {
    domain: "quant-autoplace.ru",
    slug: "quant-autoplace",
    id: 417,
    isIndex: false
  },
  {
    domain: "quant-gear.ru",
    slug: "quant-gear",
    id: 543,
    isIndex: false
  }
];
export const devDomain = {
  domain: "quant-autoplace.ru",
  slug: "quant-autoplace",
  id: 417,
  isIndex: false
};
export function currentDomain(host) {
  return domains.find(value => value.domain === host) || devDomain
}
